import { useQuery } from 'react-apollo';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import { FlyoutMessagingTrialDetailsQuery } from './queries/FlyoutMessagingTrialDetailsQuery.graphql';
import type { FlyoutMessagingTrialDetailsQuery as FlyoutMessagingTrialDetailsQueryType } from './queries/__types__/FlyoutMessagingTrialDetailsQuery';

export const useExperimentEligibility = (): Boolean => {
	const { isLoggedIn, edition, accessStatus } = useSessionData();
	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;
	const isEligibleUser = edition !== ConfluenceEdition.FREE && isLoggedIn && isLicensed;
	const { data, error, loading } = useQuery<FlyoutMessagingTrialDetailsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FlyoutMessagingTrialDetailsQuery,
		{
			skip: !isEligibleUser,
		},
	);
	if (loading || error) {
		return false;
	}
	const isTrialInstance = !!data?.license?.trialEndDate;
	return isEligibleUser && !isTrialInstance;
};
