import { useCallback } from 'react';

import {
	type NotificationResponse,
	type ResponseError,
	type Notification,
	ProductFilters,
	RequestCategory,
	RequestReadState,
} from '@atlassian/notification-types/types';
import {
	PerformanceFacade,
	useCreateFireAnalyticsFromTrigger,
	triggerNotificationsLoaded,
	triggerNotificationsFailedToLoad,
} from '@atlassian/notification-common';

import { fetchNotificationsRequest } from '../notification-log-client/fetchNotificationsClient';
import { getFlyoutLastShownNotificationId } from '../localStorage';

export type FlyoutNotificationResponse = {
	notification: Notification | null;
	unreadCount: number;
	requestCategory: RequestCategory;
};

const performance = new PerformanceFacade(window.performance);
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const LIMIT = 25;

export const useFetchMostRecentNotification = (locale: string) => {
	const fireNotificationsLoaded = useCreateFireAnalyticsFromTrigger(triggerNotificationsLoaded);
	const fireNotificationsFailedToLoad = useCreateFireAnalyticsFromTrigger(
		triggerNotificationsFailedToLoad,
	);

	const fetchData = useCallback(
		(categoryFilter: RequestCategory): Promise<NotificationResponse> => {
			const start = performance.now();
			const notificationsEndpoint = '/notifications';
			return fetchNotificationsRequest(
				categoryFilter,
				RequestReadState.UNREAD,
				ProductFilters.CONFLUENCE,
				locale,
				LIMIT,
			)
				.then((response: NotificationResponse) => {
					const duration = Math.trunc(performance.now() - start);
					fireNotificationsLoaded(true, false, duration, LIMIT, notificationsEndpoint);
					return response;
				})
				.catch((responseError: ResponseError) => {
					fireNotificationsFailedToLoad(
						true,
						false,
						LIMIT,
						responseError.code,
						notificationsEndpoint,
					);
					throw responseError;
				});
		},
		[locale, fireNotificationsLoaded, fireNotificationsFailedToLoad],
	);

	const isRecent = (notification: Notification) => {
		// Check if notification is within the last 24 hours
		return new Date(notification.timestamp).getTime() > Date.now() - ONE_DAY_IN_MS;
	};

	const isUniqueNotification = (notification: Notification) => {
		// Check if notification is not the same as the last shown notification
		return notification.id !== getFlyoutLastShownNotificationId();
	};

	const filteredNotifications = useCallback((notifications: Notification[]) => {
		return notifications.filter((notification) => {
			return isRecent(notification) && isUniqueNotification(notification);
		});
	}, []);

	const fetchFlyoutNotification = useCallback(async (): Promise<FlyoutNotificationResponse> => {
		let requestCategory = RequestCategory.DIRECT;
		let response = await fetchData(requestCategory);
		let unreadCount = response.notifications.length;
		let notifications = filteredNotifications(response.notifications);
		if (notifications.length === 0) {
			requestCategory = RequestCategory.WATCHING;
			response = await fetchData(requestCategory);
			unreadCount = response.notifications.length;
			notifications = filteredNotifications(response.notifications);
		}

		return {
			notification: notifications[0] ?? null,
			unreadCount,
			requestCategory,
		};
	}, [fetchData, filteredNotifications]);
	return { fetchFlyoutNotification };
};
