import React, { type FC, useEffect, useRef } from 'react';

import Avatar from '@atlaskit/avatar';
import { Anchor, Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import FocusRing from '@atlaskit/focus-ring';

import { type Notification, type Actor } from '@atlassian/notification-types/types';

import {
	Attribution,
	GenericErrorBoundary,
	withTransparentErrorBoundary,
} from '@confluence/error-boundary';

import { useCallbackFlyoutAnalytics } from './analytics';

const avatarWrapperStyles = xcss({
	marginRight: 'space.100',
});

export type NotificationDescriptionProps = {
	notification: Notification;
	hideFlag: () => void;
};

export type NotificationItemProps = {
	avatarUrl?: string;
	userProfileLink: string | null;
	message: string;
	contentTitle: string;
	contentLink: string;
	contentIcon: string;
	spaceName: string;
	spaceLink: string;
};

const notificationWrapperStyles = xcss({
	position: 'relative',
	padding: 'space.050',
	borderRadius: 'border.radius',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':focus': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const mainLinkStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	right: 'space.0',
	bottom: 'space.0',
	left: 'space.0',
});

const linkStyles = xcss({
	zIndex: 'flag',
	textDecoration: 'none',
	color: 'color.text.subtle',
	width: 'fit-content',

	':hover': {
		color: 'color.text.subtle',
		textDecoration: 'underline',
	},

	':active': {
		color: 'color.text',
	},
});

const AVATAR_APPEARANCE = 'circle';
const AVATAR_SIZE = 'large';

export const getUserId = (actors: Actor[]): string | null => {
	return actors[0]?.ari?.split('/')[1] ?? null;
};

export const getUserProfileLink = (userId: string | null): string | null => {
	return userId ? `${window.location.origin}/wiki/people/${userId}` : null;
};

const getNotificationItemProps = (notification: Notification): NotificationItemProps => {
	const { actors, message, entity, path } = notification.content;
	const contentTitle = entity?.title ?? '';
	const contentLink = entity?.link?.url ?? '';
	const contentIcon = entity?.icon?.url ?? '';
	const { avatarUrl } = actors[0] ?? {};
	const { title: spaceName, link } = path[0] ?? {};

	const userId = getUserId(actors);
	const userProfileLink = getUserProfileLink(userId);

	return {
		avatarUrl,
		userProfileLink,
		message,
		contentTitle,
		contentLink,
		contentIcon,
		spaceName,
		spaceLink: link?.url ?? '',
	};
};

const NotificationDescriptionWrapper: FC<NotificationDescriptionProps> = ({
	notification,
	hideFlag,
}) => {
	const {
		avatarUrl,
		userProfileLink,
		message,
		contentTitle,
		contentLink,
		contentIcon,
		spaceName,
		spaceLink,
	} = getNotificationItemProps(notification);

	const onClick = () => {
		hideFlag();
	};

	const onMainLinkClick = useCallbackFlyoutAnalytics(onClick, {
		actionSubjectId: 'main-link',
		notification,
	});

	const onContentLinkClick = useCallbackFlyoutAnalytics(onClick, {
		actionSubjectId: 'content-link',
		notification,
	});

	const onSpaceLinkClick = useCallbackFlyoutAnalytics(onClick, {
		actionSubjectId: 'space-link',
		notification,
	});

	const onAvatarClick = useCallbackFlyoutAnalytics(onClick, {
		actionSubjectId: 'avatar-user-profile-link',
		notification,
	});

	const mainAnchorRef = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		if (mainAnchorRef.current) {
			mainAnchorRef.current.focus();
		}
	}, []);

	const renderFallbackAvatar = () => <Avatar appearance={AVATAR_APPEARANCE} size={AVATAR_SIZE} />;

	return (
		<FocusRing>
			<Box xcss={notificationWrapperStyles}>
				<Anchor
					ref={mainAnchorRef}
					href={contentLink}
					xcss={mainLinkStyles}
					onClick={onMainLinkClick}
					role="link"
					testId="notification-main-link"
				/>
				<Inline>
					<Box xcss={avatarWrapperStyles}>
						<GenericErrorBoundary
							attribution={Attribution.NOTIFICATIONS}
							renderOnError={renderFallbackAvatar}
						>
							<Avatar
								href={userProfileLink ?? undefined}
								appearance={AVATAR_APPEARANCE}
								src={avatarUrl}
								size={AVATAR_SIZE}
								onClick={userProfileLink ? onAvatarClick : undefined}
								label="Avatar"
								testId="notification-avatar"
							/>
						</GenericErrorBoundary>
					</Box>
					<Stack>
						<Text weight="semibold" maxLines={2} testId="notification-message">
							{message}
						</Text>
						<Anchor
							href={contentLink}
							onClick={onContentLinkClick}
							xcss={linkStyles}
							role="link"
							testId="notification-content-link"
						>
							<Inline alignBlock="center" space="space.100">
								<Image src={contentIcon} role="presentation" width={12} height={12} />
								<Text maxLines={1}>{contentTitle}</Text>
							</Inline>
						</Anchor>
						{spaceName && (
							<Anchor
								href={spaceLink}
								onClick={onSpaceLinkClick}
								xcss={linkStyles}
								role="link"
								testId="notification-space-link"
							>
								<Text size="small" color="color.text.subtlest" maxLines={1}>
									{spaceName}
								</Text>
							</Anchor>
						)}
					</Stack>
				</Inline>
			</Box>
		</FocusRing>
	);
};

export const NotificationDescription = withTransparentErrorBoundary({
	attribution: Attribution.NOTIFICATIONS,
})(NotificationDescriptionWrapper);
