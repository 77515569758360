import React, { type FC, useState } from 'react';
import { useIntl } from 'react-intl-next';

import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import { Box, xcss, Pressable, Inline } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import { layers } from '@atlaskit/theme/constants';

import type { Notification } from '@atlassian/notification-types/types';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { useCallbackFlyoutAnalytics } from './analytics';
import { setFlyoutMessageDisabled } from './localStorage';
import { i18n } from './translations';

const buttonStyles = xcss({
	display: 'flex',
	width: '24px',
	height: '24px',
	borderRadius: 'border.radius',
	padding: 'space.025',
	alignItems: 'center',
	justifyContent: 'center',
	flex: '0 0 auto',
	borderStyle: 'none',
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	top: 'space.negative.050',
	position: 'relative',
});

const disablePopupStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	background: 'none',
	borderStyle: 'none',
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	color: 'color.text.accent.gray',
	font: 'font.body',
});

const titleInlineStyles = xcss({
	width: '300px',
});

type FlyoutDisablePopupProps = {
	notification: Notification;
	hideFlag: () => void;
};

const FlyoutDisablePopup = ({ notification, hideFlag }: FlyoutDisablePopupProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const handleClick = useCallbackFlyoutAnalytics(
		() => {
			setFlyoutMessageDisabled();
			setIsOpen(!isOpen);
			hideFlag();
		},
		{
			action: 'disabled',
			actionSubjectId: 'flyout',
			notification,
		},
	);
	const onSettingsClick = useCallbackFlyoutAnalytics(() => setIsOpen(!isOpen), {
		actionSubjectId: `settings-${!isOpen ? 'open' : 'close'}`,
		notification,
	});

	const PopupContent = () => (
		<Pressable
			xcss={disablePopupStyles}
			testId="flyout-disable-popup-content-cta"
			onClick={handleClick}
		>
			{formatMessage(i18n.disableFlyoutCtaText)}
		</Pressable>
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-end"
			content={PopupContent}
			offset={[0, 0]}
			zIndex={layers.flag()}
			trigger={(triggerProps) => (
				<Pressable
					{...triggerProps}
					xcss={buttonStyles}
					testId="flyout-disable-popup-trigger"
					backgroundColor={isOpen ? 'color.background.selected' : 'color.background.neutral.subtle'}
					onClick={onSettingsClick}
					aria-expanded={isOpen}
				>
					<MoreIcon
						label={formatMessage(i18n.settingsFlyoutCtaLabel)}
						color={isOpen ? token('color.icon.selected') : token('color.icon')}
						spacing="spacious"
					/>
				</Pressable>
			)}
		/>
	);
};

type TitleProps = {
	unreadCount: number;
	notification: Notification;
	hideFlag: () => void;
};

const TitleWrapper: FC<TitleProps & FlyoutDisablePopupProps> = ({
	unreadCount,
	notification,
	hideFlag,
}) => {
	const { formatMessage } = useIntl();
	return (
		<Inline alignInline="end" grow="hug" xcss={titleInlineStyles}>
			<Box xcss={titleInlineStyles}>
				<Heading size="xsmall" testId="flyout-title">
					{formatMessage(i18n.flagTitle, { unreadCount })}
				</Heading>
			</Box>
			<FlyoutDisablePopup notification={notification} hideFlag={hideFlag} />
		</Inline>
	);
};

export const Title = withTransparentErrorBoundary({
	attribution: Attribution.NOTIFICATIONS,
})(TitleWrapper);
