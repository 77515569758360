import React, { useMemo, forwardRef } from 'react';

import { NotificationsStoreContext } from '@atlassian/notification-common';

// HOC to provide the NotificationsStoreContext to the component
export const withNotificationsStoreContext =
	(defaultData?: any) =>
	<Props, Component>(WrappedComponent: React.ComponentType<Props> & Component) => {
		type WrappedProps = JSX.LibraryManagedAttributes<Component, Props>;

		const WithNotificationsStoreContext = forwardRef<any, WrappedProps>((props, ref) => {
			const initialContext = useMemo(
				() =>
					({
						analyticsContext: {
							requestCategory: 'direct',
							readStateFilter: 'unread',
							productFilter: 'confluence',
							view: 'list',
						},
						...defaultData,
					}) as NotificationsStoreContext,
				[],
			);

			return (
				<NotificationsStoreContext.Provider value={initialContext}>
					<WrappedComponent {...(props as any)} ref={ref} />
				</NotificationsStoreContext.Provider>
			);
		});

		WithNotificationsStoreContext.displayName = `WithNotificationsStoreContext(${
			WrappedComponent.displayName || WrappedComponent.name
		})`;

		return WithNotificationsStoreContext;
	};
