import type { Notification } from '@atlassian/notification-types/types';

declare global {
	interface Window {
		__backendOriginHost: string | undefined;
	}
}

// checks if the current page is the same as the notification content
export const isViewingNotifContent = (notification: Notification): boolean => {
	const notificationUrl = getNotifUrl(notification);

	if (!notificationUrl) {
		// err on side of assuming we are not on the same content
		// as the notification when we can't parse notification link
		return false;
	}

	const { location: currentLocation } = window;
	const currentHostname = getCurrentHostname(currentLocation.hostname); // handles localhost

	if (currentHostname !== notificationUrl.hostname) {
		return false;
	}

	const currentBasePathname = getReducedPathname(currentLocation.pathname);
	const notifBasePathname = getReducedPathname(notificationUrl.pathname);

	return currentBasePathname === notifBasePathname;
};

// removes segments of the pathname that is not an identifier of the content
// - the date segment, only present in blogs
// - the edit segment, when the content is on edit mode
// - the title segment (segment after content ID), this can change if the title was edited
// this reduces the pathname to a consistent pattern that can be compared
// e.g., /wiki/spaces/<spaceKey>/<contentType>/<contentId>
function getReducedPathname(pathname: string): string {
	// remove the date from the pathname, matches: /YYYY/MM/DD/
	const datePattern = /\/\d{4}\/\d{2}\/\d{2}\//;
	const withoutDate = pathname.replace(datePattern, '/');

	// remove the edit segment from the pathname
	const withoutDateOrEdit = withoutDate.replace('/edit-v2', '');

	// remove the title segment from the pathname
	// done AFTER date and edit are removed so that we have a reliable pattern to remove the title
	// e.g., /wiki/spaces/<spaceKey>/<contentType>/<contentId>/<title?>
	const patternBeforeTitle = /^(\/wiki\/spaces\/[^\/]+\/[^\/]+\/\d+)/;
	const match = withoutDateOrEdit.match(patternBeforeTitle);

	return match ? match[1] : pathname;
}

const getNotifUrl = (notification: Notification): URL | null => {
	try {
		const urlString = notification?.content?.entity?.link?.url ?? '';
		return new URL(urlString);
	} catch (e) {
		return null;
	}
};

function getCurrentHostname(hostname: string): string {
	return hostname === 'localhost' ? getLocalhostBackendUrl() : hostname;
}

function getLocalhostBackendUrl() {
	return window.__backendOriginHost ? `${window.__backendOriginHost}` : 'pug.jira-dev.com';
}
