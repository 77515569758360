import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import {
	type NotificationResponse,
	ProductFilters,
	RequestCategory,
	RequestReadState,
} from '@atlassian/notification-types/types';

const packageName = process.env._PACKAGE_NAME_ || '@confluence/experiment-flyout-messaging';

const packageVersion = process.env._PACKAGE_VERSION_ || 'v0.0.1';

export const fetchNotificationsRequest = async (
	categoryFilter: RequestCategory,
	readStateFilter: RequestReadState,
	productFilter: ProductFilters,
	locale: string,
	limit: number,
): Promise<NotificationResponse> => {
	const requestServiceOptions: RequestServiceOptions = {
		queryParams: {
			...(categoryFilter !== RequestCategory.ANY && {
				category: categoryFilter,
			}),
			...(productFilter !== ProductFilters.ANY && {
				product: productFilter,
			}),
			...(readStateFilter !== RequestReadState.ANY && {
				readState: readStateFilter,
			}),
			limit,
		},
		requestInit: {
			credentials: 'include',
			headers: {
				'content-type': 'application/json',
				'x-app-name': packageName,
				'x-app-version': packageVersion,
				'x-app-platform': 'web',
				'Accept-Language': locale,
			},
		},
	};

	return (await utils.requestService(
		{
			url: '/gateway/api/notification-log/api/3/notifications',
		},
		requestServiceOptions,
	)) as NotificationResponse;
};
