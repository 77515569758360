import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	flagTitle: {
		id: 'experiment-flyout-messaging.flag.title',
		defaultMessage:
			'{unreadCount, plural, =1 {One unread Confluence notification} =25 {1 of {unreadCount}+ Confluence notifications} other {1 of {unreadCount} Confluence notifications}}',
		description: 'Text describing number of unread Confluence in-app notifications',
	},
	disableFlyoutCtaText: {
		id: 'experiment-flyout-messaging.flag.disable.flyout.popup.cta.text',
		defaultMessage: 'Don’t show me messages like this',
		description: 'Button that disables flyout message',
	},
	openNotifDrawerAction: {
		id: 'experiment-flyout-messaging.flag.open.notif.drawer.action',
		defaultMessage: 'Open notifications drawer',
		description: 'Button to open the notifications drawer from the flag',
	},
	settingsFlyoutCtaLabel: {
		id: 'experiment-flyout-messaging.flag.flyout.settings.cta.label',
		defaultMessage: 'Flyout message settings',
		description: 'Label for button that opens flyout message settings',
	},
});
