import { expValEquals } from '@confluence/feature-experiments';

// https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/cc-notifications_flyout_messaging/setup
export enum ExperimentParameterValues {
	CONTROL = 'control',
	TEST = 'test',
	NOT_ENROLLED = 'not-enrolled',
}

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const isFlyoutExperimentEnabled = (): boolean =>
	expValEquals('cc-notifications_flyout_messaging', 'cohort', 'test');
