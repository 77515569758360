import React, { memo, useEffect, useCallback, useState, useContext } from 'react';
import { useIntl } from 'react-intl-next';

import NotificationIcon from '@atlaskit/icon/core/migration/notification';
import { withAnalyticsContext } from '@atlaskit/analytics-next';

import { RequestCategory } from '@atlassian/notification-types/types';

import {
	Attribution,
	withTransparentErrorBoundary,
	GenericErrorBoundary,
} from '@confluence/error-boundary';
import { type FlagsStateContainer, withFlags } from '@confluence/flags';
import { useSessionData } from '@confluence/session-data';
import {
	ExperienceSuccess,
	ExperienceTrackerContext,
	FLYOUT_MESSAGING_EXPERIENCE,
} from '@confluence/experience-tracker';
import { token } from '@atlaskit/tokens';

import { isFlyoutExperimentEnabled } from './services/experimentation';
import {
	getFlyoutMessageDisabledValue,
	getFlyoutDateLastShownValue,
	setFlyoutDateLastShown,
	setFlyoutLastShownNotificationId,
	localStorageAvailable,
} from './localStorage';
import { NotificationDescription } from './NotificationDescription';
import { Title } from './Title';
import { useExperimentEligibility } from './useExperimentEligibility';
import { i18n } from './translations';
import {
	useCallbackFlyoutAnalytics,
	useSendFlyoutAnalytics,
	FLYOUT_MESSAGING_ANALYTICS_SOURCE,
} from './analytics';
import {
	type FlyoutNotificationResponse,
	useFetchMostRecentNotification,
} from './services/flyoutNotificationsService';
import { withNotificationsStoreContext } from './withNotificationsStoreContext';
import { isViewingNotifContent } from './urlHelpers';
import type { Notification } from '@atlassian/notification-types/types';

interface FlyoutMessagingComponentProps {
	onOpenNotifDrawer: (e: any) => void;
	flags: FlagsStateContainer;
}

export const MS_BEFORE_SHOWING_FLYOUT = 60000; // 1 minutes in milliseconds

const FlyoutMessagingComponentWrapper = ({
	onOpenNotifDrawer,
	flags,
}: FlyoutMessagingComponentProps) => {
	const [notificationResponse, setNotificationResponse] = useState<FlyoutNotificationResponse>({
		notification: null,
		unreadCount: 0,
		requestCategory: RequestCategory.DIRECT,
	});
	const { formatMessage } = useIntl();
	const { locale, edition } = useSessionData();
	const isEligibleForExperiment = useExperimentEligibility();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { notification, unreadCount, requestCategory } = notificationResponse;
	const localeEditionInfo = { locale, edition };
	const extraAttributes = {
		unreadCount,
		requestCategory,
		notificationId: notification?.id,
		// To see how many flags are currently active
		activeFlagsCount: flags.state?.flags?.length ?? 0,
		...localeEditionInfo,
	};

	const FLAG_ID = 'flyout-messaging-experiment';
	const hideFlag = useCallback(() => {
		void flags.hideFlag(FLAG_ID);
	}, [flags]);

	const wasShownToday = () => {
		const lastShown = getFlyoutDateLastShownValue();
		const today = new Date().toLocaleDateString();
		return lastShown === today;
	};

	const shouldShowFlag = useCallback(() => {
		return (
			localStorageAvailable() &&
			isEligibleForExperiment &&
			isFlyoutExperimentEnabled() &&
			!getFlyoutMessageDisabledValue() &&
			!wasShownToday()
		);
	}, [isEligibleForExperiment]);

	const onClickOpenNotifDrawer = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			onOpenNotifDrawer(e);
			hideFlag();
		},
		[onOpenNotifDrawer, hideFlag],
	);

	const onOpenNotifDrawerWithAnalytics = useCallbackFlyoutAnalytics(onClickOpenNotifDrawer, {
		actionSubjectId: 'open-notifications-drawer-cta',
		notification,
		extraAttributes,
	});

	const sendDismissAnalytics = useSendFlyoutAnalytics({
		action: 'dismissed',
		actionSubjectId: 'flyout',
		notification,
		extraAttributes,
	});

	const { fetchFlyoutNotification } = useFetchMostRecentNotification(locale);

	const IconExperienceSuccess = () => {
		const Icon = () => <NotificationIcon spacing="spacious" label="" color={token('color.icon')} />;
		return (
			<GenericErrorBoundary attribution={Attribution.NOTIFICATIONS} renderOnError={Icon}>
				<ExperienceSuccess name={FLYOUT_MESSAGING_EXPERIENCE} />
				<Icon />
			</GenericErrorBoundary>
		);
	};

	const showFlag = useCallback(
		(notification: Notification) => {
			void flags.showCustomFlag({
				id: FLAG_ID,
				isAutoDismiss: true,
				onClose: sendDismissAnalytics,
				title: <Title unreadCount={unreadCount} notification={notification} hideFlag={hideFlag} />,
				description: <NotificationDescription notification={notification} hideFlag={hideFlag} />,
				customIcon: <IconExperienceSuccess />,
				actions: [
					{
						content: formatMessage(i18n.openNotifDrawerAction),
						onClick: onOpenNotifDrawerWithAnalytics,
					},
				],
			});
			setFlyoutDateLastShown();
			setFlyoutLastShownNotificationId(notification.id);
		},
		[
			flags,
			formatMessage,
			onOpenNotifDrawerWithAnalytics,
			hideFlag,
			sendDismissAnalytics,
			unreadCount,
		],
	);

	const showFlagWithAnalytics = useCallbackFlyoutAnalytics(showFlag, {
		action: 'shown',
		type: 'sendTrackEvent',
		actionSubjectId: 'flyout',
		notification,
		extraAttributes,
	});

	// Using useCallbackFlyoutAnalytics since useSendFlyoutAnalytics caused an infinite render loop
	const noop = useCallback(() => {}, []);
	const sendFlyoutStartAnalytics = useCallbackFlyoutAnalytics(noop, {
		action: 'start',
		type: 'sendTrackEvent',
		actionSubjectId: 'flyout',
		extraAttributes: localeEditionInfo,
	});

	const sendFlyoutFetchStartAnalytics = useCallbackFlyoutAnalytics(noop, {
		action: 'fetchStart',
		type: 'sendTrackEvent',
		actionSubjectId: 'flyout',
		extraAttributes: localeEditionInfo,
	});

	useEffect(() => {
		if (shouldShowFlag()) {
			// start experience here
			experienceTracker.start({
				name: FLYOUT_MESSAGING_EXPERIENCE,
			});
			sendFlyoutStartAnalytics();

			const timer = setTimeout(() => {
				sendFlyoutFetchStartAnalytics();
				fetchFlyoutNotification()
					.then((response) => {
						if (response.notification) {
							const entity = response.notification.content.entity;
							if (entity) {
								setNotificationResponse(response);
							} else {
								experienceTracker.abort({
									name: FLYOUT_MESSAGING_EXPERIENCE,
									reason: 'no entity in notification',
								});
							}
						} else {
							experienceTracker.abort({
								name: FLYOUT_MESSAGING_EXPERIENCE,
								reason: 'no notification to show',
							});
						}
					})
					.catch((e) => {
						let error = e;
						// This is to handle rejection with ResponseError (object) from fetchFlyoutNotification
						if (!(error instanceof Error)) {
							const reason = typeof e === 'object' && e.reason ? e.reason : 'Unknown error';
							const newError = new Error(reason);
							error = Object.assign(newError, e);
						}
						// fail experience here
						experienceTracker.fail({
							name: FLYOUT_MESSAGING_EXPERIENCE,
							error,
						});
					});
			}, MS_BEFORE_SHOWING_FLYOUT);
			return () => clearTimeout(timer);
		}
	}, [
		shouldShowFlag,
		fetchFlyoutNotification,
		experienceTracker,
		sendFlyoutFetchStartAnalytics,
		sendFlyoutStartAnalytics,
	]);

	useEffect(() => {
		if (notification) {
			const isViewingContentSameAsNotif = isViewingNotifContent(notification);
			if (!isViewingContentSameAsNotif) {
				showFlagWithAnalytics(notification);
			} else {
				// abort experience if user is on the same content as notification's content
				experienceTracker.abort({
					name: FLYOUT_MESSAGING_EXPERIENCE,
					reason: 'same content as notification',
				});
			}
		}
	}, [notification, hideFlag, showFlagWithAnalytics, experienceTracker]);

	return null;
};

export const FlyoutMessagingComponent = withTransparentErrorBoundary({
	attribution: Attribution.NOTIFICATIONS,
})(
	withFlags(
		memo(
			withNotificationsStoreContext()(
				withAnalyticsContext({
					source: FLYOUT_MESSAGING_ANALYTICS_SOURCE,
				})(FlyoutMessagingComponentWrapper),
			),
		),
	),
);
