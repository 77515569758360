import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { useSessionData } from '@confluence/session-data';
import { getIsSiteAdminFromQuery } from '@confluence/current-user';
import { UserPrivilegeFragment } from '@confluence/current-user/entry-points/UserPrivilege.fragment';
import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

export interface useRequestNotificationSpotlightType {
	isSiteAdmin: boolean;
	hasSpotlightBeenShown: boolean;
	markSpotlightAsShown: () => void;
}

export const UserPrivilegeQuery =
	// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming, graphql-relay-compat/no-apollo-fragments -- Read https://go/connie-relay-migration-fyi
	gql`
		query UserPrivilegeQuery {
			...UserPrivilegeFragment
		}

		${UserPrivilegeFragment}
	`;

export const useRequestNotificationSpotlight = (): useRequestNotificationSpotlightType => {
	const { cloudId, userId } = useSessionData();
	const { data: privilegeData } = useQuery(UserPrivilegeQuery, {
		skip: Boolean(process.env.REACT_SSR), // This query isn't preloaded and can be skipped during SSR to avoid unnecessary requests
	});

	const [isSiteAdmin, setIsSiteAdmin] = useState(false);
	const [hasSpotlightBeenShown, setHasSpotlightBeenShown] = useState(true);

	const spotlightLocalStorageKey = `${localStorageKeys.NOTIFICATION_SPOTLIGHT_VIEWED}_${cloudId}_${userId}`;
	const markSpotlightAsShown = useCallback(() => {
		localStorage.setItem(localStorageKeys.NOTIFICATION_SPOTLIGHT_VIEWED, true);
		setHasSpotlightBeenShown(true);
	}, [setHasSpotlightBeenShown]);

	useEffect(() => {
		setIsSiteAdmin(getIsSiteAdminFromQuery(privilegeData));
	}, [privilegeData]);

	useEffect(() => {
		setHasSpotlightBeenShown(localStorage.getItemAsBoolean(spotlightLocalStorageKey));
	}, [spotlightLocalStorageKey]);

	return {
		isSiteAdmin,
		hasSpotlightBeenShown,
		markSpotlightAsShown,
	};
};
