import { useCallback } from 'react';

import { useAnalyticsEvents, useCallbackWithAnalytics } from '@atlaskit/analytics-next';

import type { Notification } from '@atlassian/notification-types/types';

export const FLYOUT_MESSAGING_ANALYTICS_SOURCE = 'flyout-messaging';
export const FLYOUT_MESSAGING_ANALYTICS_ACTION_SUBJECT = 'flyout';

export type FlyoutAnalyticsMetadata = {
	actionSubjectId: string;
	notification?: Notification | null;
	action?: string;
	type?: string;
	extraAttributes?: Record<string, any>;
};

export const useCallbackFlyoutAnalytics = (
	method: (...args: any[]) => void,
	{
		actionSubjectId,
		notification,
		action = 'clicked',
		type = 'sendUIEvent',
		extraAttributes,
	}: FlyoutAnalyticsMetadata,
) => {
	let notificationAttributes = {};

	if (notification) {
		notificationAttributes = {
			...notification.analyticsAttributes,
			notificationId: notification.id,
		};
	}

	const attributes = { ...extraAttributes, ...notificationAttributes };
	return useCallbackWithAnalytics(method, {
		type,
		data: {
			action,
			actionSubject: FLYOUT_MESSAGING_ANALYTICS_ACTION_SUBJECT,
			source: FLYOUT_MESSAGING_ANALYTICS_SOURCE,
			actionSubjectId,
			attributes,
		},
	});
};

export const useSendFlyoutAnalytics = ({
	actionSubjectId,
	action = 'clicked',
	type = 'sendUIEvent',
	extraAttributes,
	notification,
}: FlyoutAnalyticsMetadata) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(() => {
		let notificationAttributes = {};

		if (notification) {
			notificationAttributes = {
				...notification.analyticsAttributes,
				notificationId: notification.id,
			};
		}

		const attributes = { ...extraAttributes, ...notificationAttributes };

		createAnalyticsEvent({
			type,
			data: {
				action,
				actionSubject: FLYOUT_MESSAGING_ANALYTICS_ACTION_SUBJECT,
				source: FLYOUT_MESSAGING_ANALYTICS_SOURCE,
				actionSubjectId,
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent, action, actionSubjectId, type, extraAttributes, notification]);
};
