import { confluenceLocalStorageInstance as localStorage } from '@confluence/storage-manager';

export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365;
export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
const FLYOUT_MESSAGE_LOCAL_STORAGE_PREFIX = 'flyout.message';
export const DISABLE_FLYOUT_MESSAGE_KEY = `${FLYOUT_MESSAGE_LOCAL_STORAGE_PREFIX}.disabled`;
export const FLYOUT_DATE_LAST_SHOWN_KEY = `${FLYOUT_MESSAGE_LOCAL_STORAGE_PREFIX}.date.last.shown`;
export const FLYOUT_LAST_SHOWN_NOTIFICATION_ID_KEY = `${FLYOUT_MESSAGE_LOCAL_STORAGE_PREFIX}.last.shown.notification.id`;

export const setFlyoutMessageDisabled = () => {
	localStorage.setItem(DISABLE_FLYOUT_MESSAGE_KEY, true, ONE_YEAR_IN_SECONDS);
};

export const getFlyoutMessageDisabledValue = () =>
	localStorage.getItemAsBoolean(DISABLE_FLYOUT_MESSAGE_KEY);

export const setFlyoutDateLastShown = () => {
	const currentDate = new Date().toLocaleDateString();
	localStorage.setItem(FLYOUT_DATE_LAST_SHOWN_KEY, currentDate, ONE_DAY_IN_SECONDS);
};

export const getFlyoutDateLastShownValue = () => localStorage.getItem(FLYOUT_DATE_LAST_SHOWN_KEY);

export const setFlyoutLastShownNotificationId = (id: any) => {
	localStorage.setItem(FLYOUT_LAST_SHOWN_NOTIFICATION_ID_KEY, id, ONE_DAY_IN_SECONDS);
};

export const getFlyoutLastShownNotificationId = () =>
	localStorage.getItem(FLYOUT_LAST_SHOWN_NOTIFICATION_ID_KEY);

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
export const localStorageAvailable = () => {
	let storage;
	try {
		storage = window['localStorage'];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			e.name === 'QuotaExceededError' &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
};
